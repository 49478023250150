// DEV

// export const HOME_PATH = "/obo-events";
// export const PRIMARY_API =
//   " https://gafdtmn663.execute-api.ap-south-1.amazonaws.com/dev/app";
// export const EVENT_TABLE_NAME = "OBO_EVENTS_DEV";

// PROD

export const HOME_PATH = "";
export const PRIMARY_API =
  " https://gafdtmn663.execute-api.ap-south-1.amazonaws.com/prod/app";
export const EVENT_TABLE_NAME = "OBO_EVENTS_PROD";
