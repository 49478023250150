import { ConfigProvider } from "antd";
import "./App.css";
import WholeApp from "./WholeApp";
import EventContextProvider from "./contexts/EventContext";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Fredoka",
          borderRadius: 0,
        },
      }}
    >
      <EventContextProvider>
        <WholeApp />
      </EventContextProvider>
    </ConfigProvider>
  );
}

export default App;
