import EventListerBody from "./EventListerBody/EventListerBody";
import EventListerHeader from "./EventListerHeader/EventListerHeader";
import "./Main.css";

const Main = () => {
  return (
    <div className="main-page">
      <EventListerHeader />
      <EventListerBody />
    </div>
  );
};

export default Main;
