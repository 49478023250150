import { Button, Col, Row, Select } from "antd";
import "./EventListerHeader.css";
import { useContext, useState } from "react";
import Search from "antd/es/input/Search";
import { EventContext } from "../../../contexts/EventContext";

const EventListerHeader = () => {
  const [searchvalue, setSearchValue] = useState(null);
  const { location, eventData, setLocation, filterData, setFilterData } =
    useContext(EventContext);

  const handleSearch = (newValue) => {
    setSearchValue(newValue);
    const filterEvt = eventData.filter((f) =>
      f.event_name.toLowerCase().includes(newValue.toLowerCase())
    );
    setFilterData(filterEvt);
  };
  const handleChange = (newValue) => {
    setLocation(newValue);
    setSearchValue(null);
  };

  const options = [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanniyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "The Nilgiris",
    "Thiruvallur",
    "Thiruvarur",
    "Thoothukkudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvannamalai",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ];

  return (
    <div className="eventlist-header mt-3">
      <div className="container h-100">
        <Row justify={"space-between"} align={"middle"}>
          <Col xl={6} md={8} span={24} className="my-md-0 my-1">
            <Select
              className="w-100"
              value={location}
              showSearch
              placeholder={"Select location"}
              defaultActiveFirstOption={false}
              //   onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              options={options.map((d) => ({
                value: d,
                label: d,
              }))}
            />
          </Col>
          <Col xl={8} md={10} span={24} className="my-md-0 my-1">
            <Search
              className="search-events"
              value={searchvalue}
              placeholder={`Find events in ${location}`}
              onSearch={handleSearch}
              onChange={(e) => setSearchValue(e.target.value)}
              enterButton={
                <Button type="primary" className="event-app-btn">
                  Search
                </Button>
              }
              allowClear
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EventListerHeader;
