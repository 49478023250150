import { Button, Col, Row } from "antd";
import "./Header.css";
import HeaderMenu from "./HeaderMenu";
import { HOME_PATH } from "../../constants/values";
import { FaInstagram, FaFacebookF } from "react-icons/fa";

const Header = () => {
  return (
    <div className="header-page">
      <div className="container h-100">
        <Row justify={"space-between"} align={"middle"} className="h-100">
          <Col className="header-logo-box">
            <img
              src={`${HOME_PATH}/assets/logo.png`}
              alt="logo-img"
              className="col-2 logo-img"
            />
            <span className="logo-name ms-1">Ezy Ticket</span>
          </Col>
          {/* <Col>
            <HeaderMenu />
          </Col> */}
          {/* <Col>
            <Button type="primary" className="event-app-btn">
              Login
            </Button>
          </Col> */}
          <Col>
            <Button
              size="small"
              shape="circle"
              type="primary"
              className="event-app-btn me-2"
            >
              <a
                href="https://www.instagram.com/obo_technology/"
                target="_blank"
              >
                <FaInstagram />
              </a>
            </Button>
            <Button
              size="small"
              shape="circle"
              type="primary"
              className="event-app-btn"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/p/OBO-Robotics-Lab-100064083400147//"
              >
                <FaFacebookF />
              </a>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
