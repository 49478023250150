import { Button, Card } from "antd";
import "./EventCard.css";
import { HOME_PATH } from "../../../constants/values";

const EventCard = ({ eventItem }) => {
  const onOpenEvent = (event) => {
    window.location.href = `/event-details/index.html?location=${event.event_location}&eventid=${event.event_id}`;
  };
  return (
    <Card
      bordered={false}
      className="event-card h-100"
      cover={
        <img alt={eventItem.event_name} src={`${eventItem?.event_coverimg}`} />
      }
    >
      <p>{eventItem.event_name}</p>
      <p>
        <Button
          className="event-app-btn2"
          type="primary"
          onClick={() => onOpenEvent(eventItem)}
        >
          GET INFO
        </Button>
      </p>
    </Card>
  );
};

export default EventCard;
