import { Col, Result, Row } from "antd";
import "./EventListerBody.css";
import { useContext } from "react";
import { EventContext } from "../../../contexts/EventContext";
import EventCard from "../EventCard/EventCard";

const EventListerBody = () => {
  const { filterData } = useContext(EventContext);
  return (
    <div className="eventlister-body">
      <div className="container mt-3">
        {filterData.length > 0 ? (
          <Row
            gutter={[16, 16]}
            justify={"start"}
            align={"stretch"}
            className="h-100"
          >
            {filterData?.map((evt, i) => (
              <Col className="" xxl={6} lg={6} md={8} sm={8} span={12} key={i}>
                <EventCard eventItem={evt} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row justify={"center"} align={"middle"} style={{ height: "75vh" }}>
            <Result
              title="No results found"
              extra={
                <>
                  Sorry, your search did not match any records. Please try again
                  with different keywords
                </>
              }
            />
          </Row>
        )}
      </div>
    </div>
  );
};

export default EventListerBody;
