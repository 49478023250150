import { createContext, useEffect, useMemo, useState } from "react";
import { allEventdata } from "./EventData";
import { EVENT_TABLE_NAME, PRIMARY_API } from "../constants/values";
import { postApi } from "../Api/postApi";

export const EventContext = createContext();

const EventContextProvider = ({ children }) => {
  const [location, setLocation] = useState("Kanniyakumari");
  const [eventData, setEventData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    onGetEvent(location);
  }, [location]);

  const getEventData = (location) => {
    const filterEvents = allEventdata.filter((f) => f.event_city === location);
    setEventData(filterEvents);
    setFilterData(filterEvents);
  };

  const onGetEvent = async (location) => {
    const url = `${PRIMARY_API}/get-filter-data`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const filterQuery = [
      { label: "event_location", value: location, condition: "=" },
    ];
    const body = { tableName: EVENT_TABLE_NAME, filter_query: filterQuery };

    try {
      const result = await postApi(url, options, body);

      if (result.statusCode === 200) {
        setEventData(result.body);
        setFilterData(result.body);
      }
    } catch (error) {
      // Handle errors
    }
  };

  const values = useMemo(
    () => ({
      eventData,
      setEventData,
      location,
      setLocation,
      filterData,
      setFilterData,
    }),
    [eventData, location, filterData]
  );

  return (
    <EventContext.Provider value={values}>{children}</EventContext.Provider>
  );
};

export default EventContextProvider;
